const type = process.env.GATSBY_VALDON || "group";

export const content = {
  group: {
    companyName: "Valdon Group GmbH",
    copyrightFooter: "2015-2024 Valdon Group GmbH",
    color: "#e0242c",
    rgb: "rgba(224, 36, 44, 0.8)",
    introTags: ["Quant Finance", "Asset Optimization", "Software Engineering", "Data Modeling"],
    backgroundBuilding: "building.jpg",
    logoMenu: "menu.png",
    logoLinkTwitter: "twitter.png",
    logoLinkEmail: "mail.png",
    logoLinkLinkedIn: "linkedin.png",
    logoBw: "logo.png",
    logo: "valdongroup-logo.png",
    textLogo: "valdongroup.png",
    video: "zrh.mp4",
    location: { lat: 47.3755242, lng: 8.5450829 },
    researchStatement:
      "At Valdon, research is a crucial ingredient. It is the actual process through which innovation is reached, in academia and industry. The Valdon Group is constantly with an eye on the latest academic research, and most group members themselves are actively conducting research and publishing papers. Research and publications are mainly based in the field of financial econometrics and time series analysis.",
    mission:
      "Valdon Group is a Swiss-German fintech supplier located in the center of Zurich, Switzerland. The company's goal is to translate cutting edge academic knowledge, research and technology into consultancy and software services designed for regulatory compliancy and decision making in the ever growing field of financial technology. The repertoire of expertise covers autonomous advisory systems, model validation, model development, portfolio optimization, market making, risk evaluation and assessment, as well as data science, statistical inference, machine learning, artificial intelligence and blockchain. Our team is specialized in E2E software design and development tailored to the needs of financial technology firms. As a natural companion, Valdon also offers plain technology and quant finance consulting.",
    services: [
      {
        title: "Model Building",
        subtitle: "Develop, build, and implement",
        description:
          "Valdon is a specialist in model development and building with focus on large scale risk and trading models for assessing and predicting business related quantities of time series. Examples are rating tools, value-at-risk and expected shortfall forecasts, quantile predictions, and dynamic margin requirement models for exchanges and clearing houses. Particular attention is given to most recent developments in technology and research in univariate and multivariate time series modeling and applications."
      },
      {
        title: "Data Analysis",
        subtitle: "Big data and deep learning made real",
        description:
          "Data science is a good deal more valuable than most firms realize, and a great untapped resource. By combining the various types of data accumulated in a company's daily business, potentially scattered around different departments, systems and entities, business activities can be substantially enhanced and refined. Valdon offers the necessary knowhow, statistical expertise, partnerships and technology to uncover, analyze and process big data. This includes the latest endeavors in machine learning."
      },
      {
        title: "Optimization",
        subtitle: "Making the most of it",
        description:
          "Optimization is key to success in understanding complex systems. Valdon offers specialists' knowledge and tailored solutions to optimization problems in statistics, econometrics, and other data related fields, such as bioinformatics and computer vision. Topics in finance include risk management, option pricing, high frequency data analysis, and portfolio optimization, to name a few. Modern optimization methods and techniques are a cornerstone of Valdon and available in-house. Also, Valdon covers the technical side of the story."
      },
      {
        title: "Model Validation",
        subtitle: "Regulatory Compliance",
        description:
          "Being regulatory compliant is becoming stringent and a competitive factor. Due to soaring regulators' demand for better analytics and more frequent reporting, banks, insurers and other financial institutions need to continuously validate their internal risk and trading models. Valdon provides a suite of validations for a broad spectrum of risk models: variance, value-at-risk, expected shortfall, portfolio downside risk, time series risk factors, rating tools, and others; tailored to individual requirements and specifications if desired."
      }
    ],
    leadership: [
      {
        name: "Lars Jochen Krause, PhD",
        position: "Managing Partner",
        image: "people/jochen.jpg",
        description:
          "Jochen has a track record of academic research of more than ten years since his first MSc in Economics at the University of Kiel, Germany. In addition, he holds a MSc in Computer Science from the University of Kiel, and a PhD in Finance from the University of Zürich. He worked as Senior Research Associate at the Department of Banking and Finance of the University of Zürich, supported by the Swiss National Science Foundation, for years. Other positions cover portfolio management (robo-advisory), and data science (retail). His background is in computer vision, statistics, data analytics, time series analysis, econometrics, risk modeling, and portfolio optimization. Jochen is a passionate developer and specialist for large-scale optimization problems and model development. He is Co-Founder of Valdon Group, and our Head of Operations."
      },
      {
        name: "Sven Christian Steude, PhD",
        position: "Managing Partner",
        image: "people/sven.jpg",
        description:
          "Sven has decande long extensive experience in the financial services industry after his graduation at the University of Kiel, Germany, of what would be nowadays called a MSc in Data Science. He continued with working for the Department of Banking and Finance at the University of Zurich and then moved on to become an exotics trader at JPMorgan's equity trading floor in London, mostly involved in hybrids derivatives trading and hedging. The entrepreneurial spirit of Dr. Steude led him further to quantitative consulting in the area of risk prediction for various trading firms, clearing houses, hedge funds and quant consulting firms, and one of which he now co-founded himself (2015). Dr. Steude holds a PhD in Econometrics from the University of Zurich, Switzerland. For more than seven years now, he is leading the Hackaton and the 'Banking and Finance 2.0' start-up seminar of the University of Zurich (Department of Informatics and Department of Banking and Finance), where he is also a senior lecturer in Options-Trading and Statistics and Econometrics."
      }
    ],
    legals: [
      {
        title: "RECHTSHINWEIS / LEGAL NOTE",
        text: "Rechtliche Hinweise"
      },
      {
        title: "HAFTUNGSAUSSCHLUSS",
        text:
          'Eine Haftung jeglicher Art für die Inhalte dieser Internetpräsenz und deren Nutzung durch die Valdon Group GmbH sowie Haftungsansprüche und Schadenersatz wegen fehlerhafter oder fehlender Angaben sind grundsätzlich ausgeschlossen. Es besteht keine Gewähr für Aktualität, Korrektheit oder Vollständigkeit der gemachten Angaben. Alle Angaben erfolgen nach bestem Wissen und Gewissen. Die Unwirksamkeit einzelner Formulierungen berührt andere Formulierungen in Bezug auf Inhalt und Gültigkeit nicht. Die Verwendung von Ausdrücken wie "Valdon" oder "Valdon Group" innerhalb dieser Internetpräsenz bezieht sich auf die Valdon Group GmbH in Zürich am Main im Allgemeinen.'
      },
      {
        title: "LINKS UND VERWEISE",
        text:
          "Für die Inhalte verlinkter Seiten (externer Links) sind ausschliesslich deren Betreiber verantwortlich. Haftungsansprüche für Links sind grundsätzlich ausgeschlossen, sofern seitens der Valdon Group GmbH kein vorsätzliches oder grob fahrlässiges Verschulden vorliegt."
      },
      {
        title: "URHEBERRECHT",
        text:
          "Die Inhalte auf dieser Internetpräsenz der Valdon Group GmbH sind urheberrechtlich geschützt. Sofern nicht anders vermerkt, ist für die Verwendung, Weitergabe oder Auswertung der Texte, der Grafiken und Logos, der Designs und anderer Inhalte eine schriftliche Genehmigung durch die Valdon Group GmbH erforderlich."
      },
      {
        title: "DATENSCHUTZERKLÄRUNG",
        text:
          "Die Valdon Group legt grossen Wert auf den Schutz von personenbezogenen Daten. Entsprechend folgen wir den gesetzlichen Anforderungen des schweizerischen Bundesgesetzes über den Datenschutz, revDSG, den Ausführungsbestimmungen in der neuen Datenschutzverordnung, dem Fernmeldegesetz und anderen relevanten Bestimmungen des schweizerischen Datenschutzrechts."
      },
      {
        title: "DATENSICHERHEIT",
        text:
          "Im Internet gibt es keine absolute Sicherheit bei der Datenübertragung. Dies gilt insbesondere für die Übertragung und das Versenden von E-Mails. Schicken sie uns daher bitte unter keinen Umständen vertrauliche Daten über das Internet. Für Schäden, die durch den Missbrauch von vertraulichen Daten durch unbefugte Dritte entstehen, übernimmt die Valdon Group GmbH keine Haftung."
      },
      {
        title: "DATENSCHUTZBEAUFTRAGTER",
        text: "Dr. Lars Jochen Krause"
      },
      {
        title: "GERICHTSSTAND",
        text: "Es gilt Schweizer Recht. Gerichtsstand ist Zürich."
      }
    ],
    responsible: {
      title: "VERANTWORTLICH IM SINNE DES PRESSERECHTS",
      name: "Dr. Sven Christian Steude "
    },
    addresses: {
      domicile: [
        "Valdon Group GmbH",
        "Waidfussweg 35",
        "8037 Zürich",
        "Switzerland"
      ],
      office: [
        "Valdon Group GmbH",
        "Seidengasse 6",
        "8001 Zürich",
        "Switzerland"
      ]
    },
    contact: {
      number: "+41 44 520 95 64",
      email: "contact@valdon.ch",
      website: "www.valdon.ch"
    },
    social: {
      linkedin: "https://www.linkedin.com/company/valdon-group-gmbh",
      twitter: "https://twitter.com/ValdonMesh"
    },
    lead: {
      title: "GESCHÄFTSFÜHRUNG",
      names: ["Dr. Lars Jochen Krause", "Dr. Sven Christian Steude"],
      text: [
        "Sitz: Zürich",
        "Gründungsjahr: 2015",
        "Referenz im Handelsregister: CH-020.4.057.039-5",
        "Unternehmens-Identifikationsnummer: CHE-325.047.864",
        "Gesellschaftsform: Gesellschaft mit beschränkter Haftung, GmbH"
      ]
    }
  },
  mesh: {
    companyName: "Covee Financial Data Science GmbH",
    copyrightFooter: "2017-2024 Covee Financial Data Science GmbH",
    color: "#55C0B0",
    rgb: "rgba(85, 192, 176, 0.8)",
    introTags: ["Quant Finance", "Asset Optimization", "Software Engineering", "Data Modeling"],
    backgroundBuilding: "building.jpg",
    logoMenu: "menu.png",
    logoLinkTwitter: "twitter.png",
    logoLinkEmail: "mail.png",
    logoLinkLinkedIn: "linkedin.png",
    logoBw: "logo.png",
    logo: "valdonmesh-logo.png",
    textLogo: "valdonmesh.png",
    location: { lat: 50.1123876, lng: 8.6523799 },
    video: "fra.mp4",
    researchStatement:
      "At Valdon, R&D is extremely important. It is the actual process through which innovation is reached. The Valdon team is constantly with an eye on the latest academic research, and its members themselves are still actively publishing. Most publications are in the field of financial econometrics and time series analysis. Research output finds applications in the field of commodity pricing, model validation, portfolio optimization, and risk management.",
    mission:
      "Valdon is a software and consulting boutique in the field of financial technology and quant finance based on the belief that business is about people and the transfer of knowledge. We are passionate about our mission to bridge the gap between academia and industry by transfering cutting edge academic knowledge and research into consultancy and technology services, delivering fully fledged solutions to the financial industry. Valdon consists of a dedicated and driven group of individuals operating with great levels of integrity, quality, and transparency. We provide services designed for regulatory compliance and business enhancement and strives for continuous and sustainable innovation. Members of Valdon have extensive work experience with risk and trading models of large private and investment banks as well as for international clearing houses on the one hand, and deep knowledge of the theoretical frameworks from their academic careers and research affiliations on the other hand. In addition, we offer customer tailored consultancy services and solutions in model validation, model development, evaluation and assessment of financial risk, and other fields. Valdon Mesh GmbH was founded 2015 in Frankfurt and merged in 2024 with its Berlin based subsidiary Covee Financial Data Science GmbH.",
    services: [
      {
        title: "Model Building",
        subtitle: "Develop, build, and implement",
        description:
          "Valdon is a specialist in model development and building with focus on large scale risk and trading models for assessing and predicting business related quantities of time series. Examples are rating tools, value-at-risk and expected shortfall forecasts, quantile predictions, and dynamic margin requirement models for exchanges and clearing houses. Particular attention is given to most recent developments in technology and research in univariate and multivariate time series modeling and applications."
      },
      {
        title: "Data Analysis",
        subtitle: "Big data and deep learning made real",
        description:
          "Data science is a good deal more valuable than most firms realize, and a great untapped resource. By combining the various types of data accumulated in a company's daily business, potentially scattered around different departments, systems and entities, business activities can be substantially enhanced and refined. Valdon offers the necessary knowhow, statistical expertise, partnerships and technology to uncover, analyze and process big data. This includes the latest endeavors in machine learning."
      },
      {
        title: "Optimization",
        subtitle: "Making the most of it",
        description:
          "Optimization is key to success in understanding complex systems. Valdon offers specialists' knowledge and tailored solutions to optimization problems in statistics, econometrics, and other data related fields, such as bioinformatics and computer vision. Topics in finance include risk management, option pricing, high frequency data analysis, and portfolio optimization, to name a few. Modern optimization methods and techniques are a cornerstone of Valdon and available in-house. Also, Valdon covers the technical side of the story."
      },
      {
        title: "Model Validation",
        subtitle: "Regulatory Compliance",
        description:
          "Being regulatory compliant is becoming stringent and a competitive factor. Due to soaring regulators' demand for better analytics and more frequent reporting, banks, insurers and other financial institutions need to continuously validate their internal risk and trading models. Valdon provides a suite of validations for a broad spectrum of risk models: variance, value-at-risk, expected shortfall, portfolio downside risk, time series risk factors, rating tools, and others; tailored to individual requirements and specifications if desired."
      }
    ],
    leadership: [
      {
        name: "Lars Jochen Krause, PhD",
        position: "Managing Partner",
        image: "people/jochen.jpg",
        description:
          "Jochen has a track record of academic research of more than ten years since his first MSc in Economics at the University of Kiel, Germany. In addition, he holds a MSc in Computer Science from the University of Kiel, and a PhD in Finance from the University of Zürich. He worked as Senior Research Associate at the Department of Banking and Finance of the University of Zürich, supported by the Swiss National Science Foundation, for years. Other positions cover portfolio management (robo-advisory), and data science (retail). His background is in computer vision, statistics, data analytics, time series analysis, econometrics, risk modeling, and portfolio optimization. Jochen is a passionate developer and specialist for large-scale optimization problems and model development. He is Co-Founder of Valdon Mesh, and our Head of Operations."
      },
      {
        name: "Sven Christian Steude, PhD",
        position: "Managing Partner",
        image: "people/sven.jpg",
        description:
          "Sven has worked in the financial services industry for more than a decande after his graduation at the University of Kiel, Germany, of what would be nowadays called a MSc in Data Science. He continued with working for the Department of Banking and Finance at the University of Zurich and then moved on to become an exotics trader at JPMorgan's equity trading floor in London, mostly involved in hybrids derivatives trading and hedging. The entrepreneurial spirit of Dr. Steude led him further to quantitative consulting in the area of risk prediction for various trading firms, clearing houses, hedge funds and quant consulting firms, and one of which he now co-founded himself (2015). Dr. Steude holds a PhD in Econometrics from the University of Zurich, Switzerland. For more than seven years now, he is leading the Hackaton and the 'Banking and Finance 2.0' start-up seminar of the University of Zurich (Department of Informatics and Department of Banking and Finance), where he is also a senior lecturer in Options-Trading and Statistics and Econometrics."
      }
    ],
    legals: [
      {
        title: "RECHTSHINWEIS / LEGAL NOTE",
        text: "Rechtliche Hinweise"
      },
      {
        title: "HAFTUNGSAUSSCHLUSS",
        text:
          'Eine Haftung jeglicher Art für die Inhalte dieser Internetpräsenz und deren Nutzung sowie Haftungsansprüche und Schadenersatz wegen fehlerhafter oder fehlender Angaben sind grundsätzlich ausgeschlossen. Es besteht keine Gewähr für Aktualität, Korrektheit oder Vollständigkeit der gemachten Angaben. Alle Angaben erfolgen nach bestem Wissen und Gewissen. Die Unwirksamkeit einzelner Formulierungen berührt andere Formulierungen in Bezug auf Inhalt und Gültigkeit nicht. Die Verwendung von Ausdrücken wie "Valdon" oder "Valdon Mesh", innerhalb dieser Internetpräsenz bezieht sich auf die Marke Valdon Mesh der Covee Financial Data Science GmbH in Berlin im Allgemeinen.'
      },
      {
        title: "LINKS UND VERWEISE",
        text:
          "Für die Inhalte verlinkter Seiten (externer Links) sind ausschliesslich deren Betreiber verantwortlich. Haftungsansprüche für Links sind grundsätzlich ausgeschlossen, sofern seitens der Valdon Mesh GmbH kein vorsätzliches oder grob fahrlässiges Verschulden vorliegt."
      },
      {
        title: "URHEBERRECHT",
        text:
          "Die Inhalte auf dieser Internetpräsenz der Valdon Mesh GmbH sind urheberrechtlich geschützt. Sofern nicht anders vermerkt, ist für die Verwendung, Weitergabe oder Auswertung der Texte, der Grafiken und Logos, der Designs und anderer Inhalte eine schriftliche Genehmigung durch die Valdon Mesh GmbH erforderlich."
      },
      {
        title: "DATENSCHUTZERKLÄRUNG",
        text:
          "Alle Daten werden gemäss den Bestimmungen des deutschen Datenschutzrechts sowie der europäischen Datenschutz-Grundverordnung, DS-GVO, verarbeitet und geschützt."
      },
      {
        title: "DATENSICHERHEIT",
        text:
          "Im Internet gibt es keine absolute Sicherheit bei der Datenübertragung. Dies gilt insbesondere für die Übertragung und das Versenden von E-Mails. Schicken sie uns daher bitte unter keinen Umständen vertrauliche Daten über das Internet. Für Schäden, die durch den Missbrauch von vertraulichen Daten durch unbefugte Dritte entstehen, übernimmt die Valdon Mesh GmbH keine Haftung."
      },
      {
        title: "DATENSCHUTZBEAUFTRAGTER",
        text: "Dr. Lars Jochen Krause"
      },
      {
        title: "GERICHTSSTAND",
        text: "Es gilt Deutsches Recht. Gerichtsstand ist Berlin."
      }
    ],
    responsible: {
      title: "VERANTWORTLICH IM SINNE DES PRESSERECHTS",
      name: "Dr. Sven Christian Steude "
    },
    addresses: {
      domicile: [
        "Covee Financial Data Science GmbH",
        "Einstein Palais",
        "Friedrichstrasse 171",
        "10117 Berlin",
        "Germany"
      ],
      office: [
        "Covee Financial Data Science GmbH",
        "attn. Valdon Mesh",
        "Friedrichstrasse 171",
        "10117 Berlin",
        "Germany"
      ]
    },
    social: {
      linkedin: "https://www.linkedin.com/company/valdon-mesh-gmbh/",
      twitter: "https://twitter.com/ValdonMesh"
    },
    contact: {
      number: "+49 69 34 87 66 87",
      email: "contact@valdon.de",
      website: "www.valdon.de"
    },
    lead: {
      title: "GESCHÄFTSFÜHRUNG",
      names: ["Dr. Sven Christian Steude", "Dr. Lars Jochen Krause"],
      text: [
        "Gründungsjahr: 2017",
        "Registerblatt: HRB 187624",
        "Registergericht: Amtsgericht Charlottenburg Berlin",
        "Gesellschaftsform: Gesellschaft mit beschränkter Haftung, GmbH"
      ]
    }
  }
};

export const DATA = content[type];
